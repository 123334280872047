
// SCRIPTS

import { SecureHomePage } from './home/home.page'; export { SecureHomePage };
// import { UserDirectoryPage } from './communications/user-directory/user-directory.page'; export { UserDirectoryPage };
// import { TaskTrackerPage } from './task-tracking/scripts/task-tracker.page'; export { TaskTrackerPage };
// import { ContractsPage } from './reporting/contracts/scripts/contracts.page'; export { ContractsPage };
// import { SitesPage } from './reporting/sites/scripts/sites.page'; export { SitesPage };
//import { NewsEventsPage } from './news-events/news-events.page'; export { NewsEventsPage };
////import { StudiesPage } from './studies/scripts/studies.page'; export { StudiesPage };
//import { AboutPage } from './about/about.page'; export { AboutPage };
//import { FaqsPage } from './faqs/faqs.page'; export { FaqsPage };
//import { ResearchPage } from './research/research.page'; export { ResearchPage };
//import { WhoWeArePage } from './who-we-are/who-we-are.page'; export { WhoWeArePage };
//import { ResearchWorksPage } from './research-works/research-works.page'; export { ResearchWorksPage };
//import { CommunityPage } from './community/community.page'; export { CommunityPage };
//import { LongCovidPage } from './long-covid/long-covid.page'; export { LongCovidPage };

// STYLES
import './home/home.scss';
// import './communications/user-directory/user-directory.scss';
// import './task-tracking/task-tracker.scss';
// import './reporting/contracts/contracts.scss';
// import './reporting/sites/sites.scss';

//import './contact-us/contact-us.scss';
//import './funding/funding.scss';
//import './news-events/news-events.scss';
//import './about/about.scss';
//import './faqs/faqs.scss';
//import './research/research.scss';
//import './who-we-are/who-we-are.scss';
//import './research-works/research-works.scss';
//import './community/community.scss';
//import './long-covid/long-covid.scss';

