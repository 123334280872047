import { CountUp } from 'countup.js';
import 'waypoints/lib/jquery.waypoints.min';


export class SecureHomePage {

    constructor() {

        //document.ready
        $(() => {

            this.initializeCounters();

        });

    }

    initializeCounters(): void {

        (<any>$('.countup')).waypoint({
            handler: function () {
                const counter = new CountUp(this.element, this.element.textContent, { useEasing: false, duration: 1 });
                if (!counter.error) {
                    counter.start();
                }
                this.destroy();
            },
            offset: 'bottom-in-view'
        });
    }
}
